<template>
  <div>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <!-- /Brand logo-->
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <loginSvgWhite v-if="imgUrl === 'Light'" />
            <loginSvgDark v-if="imgUrl === 'Dark'" />
          </div>
        </b-col>
        <!-- /Left Text-->
        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-col>
              <b-img
                class="mb-1 mb-sm-0"
                height="35"
                width="auto"
                src="../assets/animations/CRM.svg"
              />
            </b-col>
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  v-if="!userVerified.nome"
                  label="CPF"
                  label-for="login-cpg"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="CPF"
                    rules="required"
                  >
                    <the-mask
                      v-model="userCpf"
                      class="form-control"
                      :mask="['###.###.###-##', '##.###.###/####-##']"
                      placeholder="000.000.000-00"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >O campo CPF é obrigatório!</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-else>
                  <b-form-group v-if="userVerified.funcao === 'ADMINISTRADOR MASTER'">
                    <label for="name">Nome</label>
                    <validation-provider name="name">
                      <b-form-input
                        id="name"
                        class="form-control-merge"
                        name="name"
                        :value="userVerified.nome"
                        readonly
                      />
                    </validation-provider>
                    <label for="name">Função</label>
                    <validation-provider name="funcao">
                      <b-form-input
                        id="name"
                        class="form-control-merge"
                        name="name"
                        :value="userVerified.funcao"
                        readonly
                      />
                    </validation-provider>
                  </b-form-group>
                  <b-form-group v-else>
                    <label for="name">Nome</label>
                    <validation-provider name="name">
                      <b-form-input
                        id="name"
                        class="form-control-merge"
                        name="name"
                        :value="userVerified.nome"
                        readonly
                      />
                    </validation-provider>
                    <label for="name">Empresa</label>
                    <validation-provider name="empresa">
                      <b-form-input
                        id="name"
                        class="form-control-merge"
                        name="name"
                        :value="userVerified.empresa.nome_fantasia"
                        readonly
                      />
                    </validation-provider>
                  </b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Senha</label>
                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                      <small>Esqueceu a senha?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >O campo Senha é obrigatório!</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Lembrar - me
                  </b-form-checkbox>
                </b-form-group>
                <div v-if="!userVerified.nome">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    @keyup.enter="verificarAPI()"
                    @click="verificarAPI()"
                  >
                    Entrar
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    @keyup.enter="validationForm()"
                    @click="validationForm()"
                  >
                    Entrar
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { TheMask } from 'vue-the-mask'

import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
// import Cleave from 'vue-cleave-component'
import axios from '../../axios-auth'
import loginSvgWhite from '../assets/animations/LoginAnimationWhite.vue'
import loginSvgDark from '../assets/animations/LoginAnimationDark.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    loginSvgWhite,
    loginSvgDark,
    TheMask,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      cpf_cnpj: '',
      cpfValid: false,
      status: '',
      password: '',
      userCpf: '',
      sideImg: '',
      userVerified: {},
      mask: {
        cpfCnpj: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
      },
      // validation rulesimport store from '@/store/index'
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return 'Dark'
      }
      return 'Light'
    },
  },
  methods: {
    async verificarAPI() {
      try {
        const response = await axios.post('api/prelogin', {
          cpf_cnpj: this.userCpf,
        })
        const { nome, funcao, empresa } = response.data.dados[0]
        this.userVerified = { nome, funcao, empresa }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Credenciais Inválidas',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    validationForm() {
      this.userCpf = this.userCpf.replace(/[^0-9]/g, '')
      this.$store.dispatch('auth/login', { cpf_cnpj: this.userCpf, password: this.password }).finally(() => {
        if (!localStorage.getItem('token')) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Credenciais Inválidas',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss'
</style>
